import { getAction,getByIdAction,postAction,uploadFile,deleteById } from '@/api/common/request'
const baseURL = 'xun/work/xunLikeBooks'

const checkExistApi =(params)=>postAction(baseURL+"/checkExist",params);

const quickRecordApi=(params)=>postAction(baseURL+'/quickRecord',params);

const listBookApi=(params)=>getAction(baseURL+'/list',params);


const batchImportApi=(params)=>uploadFile(baseURL+'/batchImport',params);

const selectByIdtApi=(params)=>getByIdAction(baseURL+'/selectById',params);


const saveOrUpdateDataApi=(params)=>postAction(baseURL+'/saveOrUpdateData',params);

export{
    checkExistApi,
    quickRecordApi,
    listBookApi,
    batchImportApi,
    selectByIdtApi,
    saveOrUpdateDataApi
}
